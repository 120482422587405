<template>
  <div class="qingwu">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
            >首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>设备管理</el-breadcrumb-item>
          <el-breadcrumb-item>入库列表</el-breadcrumb-item>
          <el-breadcrumb-item>新增入库</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <el-form ref="form" :model="form">
      <div class="admin_main_block admin_m15">
        <el-row :gutter="40">
          <el-col :span="24">
            <span class="grid-divider">基本信息</span>
            <el-divider></el-divider>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="设备类型"
              prop="deviceType"
              :rules="[
                { required: true, message: '请选择设备类型', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="form.deviceType"
                placeholder="请选择"
                clearable
                @change="getDeviceTypeNo"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="(item, i) in deviceTypeList"
                  :label="item.deviceName"
                  :value="item.deviceType"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="设备厂商"
              prop="deviceManufacturer"
              :rules="[
                { required: true, message: '请选择设备厂商', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="form.deviceManufacturer"
                placeholder="请选择"
                clearable
                @change="getDeviceTypeNo"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="(item, i) in deviceManufacturerList"
                  :label="item.manufacturerName"
                  :value="item.manufacturerCode"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="span" class="hight">
            <el-form-item
              label="设备型号"
              prop="typeNo"
              :rules="[
                { required: true, message: '请选择设备型号', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="type"
                placeholder="请选择"
                clearable
                @change="setTypeNo"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  v-for="(item, i) in deviceTypeNoList"
                  :label="item.typeName"
                  :value="i"
                  :key="i"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24" class="hight sn">
            <el-form-item label="设备序列号（S/N）">
              <el-row>
                <el-col :span="24">
                  S/N前缀:
                  <el-input
                    v-model="form.prefix"
                    style="width: 150px"
                    disabled
                    placeholder="例如：GS"
                  ></el-input>
                  ,起
                  <el-input-number
                    v-model="form.posSnStart"
                    controls-position="right"
                    placeholder="编号"
                    :min="1"
                    :max="999999999999999999"
                    clearable
                    @change="setNumber"
                  ></el-input-number>
                  止
                  <el-input-number
                    v-model="form.posSnEnd"
                    controls-position="right"
                    placeholder="编号"
                    :min="1"
                    :max="999999999999999999"
                    clearable
                    @change="setNumber"
                  ></el-input-number>
                  ，共
                  <el-input
                    type="number"
                    v-model="form.counts"
                    style="width: 100px"
                    placeholder="结算"
                    :disabled="true"
                  ></el-input>
                  台。
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="admin_main_block admin_m15">
        <el-row :gutter="40">
          <el-col :span="span" class="hight">
            <el-form-item
              label="设备押金"
              prop="price"
              :rules="[
                { required: true, message: '请输入设备押金', trigger: 'blur' },
              ]"
            >
              <el-input-number
                v-model="form.price"
                controls-position="right"
                placeholder="请输入设备押金:1-9999"
                :min="1"
                :max="9999"
                class="price"
                clearable
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="admin_main_block admin_m15">
        <el-row :gutter="40">
          <!--<el-col :span="span" class="hight">
                        <el-form-item label="活动政策" prop="realName" :rules="[{required:true,message:'请选择活动政策',trigger: 'blur' }]">
                            <el-select v-model="form.idCardNo" placeholder="请选择活动政策" clearable>
                                <el-option label="未完成" value=""></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>-->

          <el-col :span="24">
            <el-button icon="el-icon-back" type="primary" @click="back">
              取消
            </el-button>
            <el-button
              icon="el-icon-check"
              type="primary"
              @click="success('form')"
            >
              提交
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      disabled: false,
      span: 6,
      actionUploadUrl: "",
      checked: true,
      deviceTypeList: "",
      deviceManufacturerList: "",
      deviceTypeNoList: "",
      type: "",
      form: {
        counts: 1,
        creater: "",
        deviceManufacturer: "",
        deviceType: "",
        posSnEnd: "",
        posSnStart: "",
        prefix: "",
        price: 99,
        typeNo: "",
      },
    };
  },
  methods: {
    success(formName) {
      if (!this.setNumber()) {
        return false;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              const url = this.$api.deviceCreate;
              this.post(url, this.form);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          this.$message({
            type: "warning",
            message: "请填写完整信息！",
          });
          return false;
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功",
          });
          this.back();
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    getUploadFile(val) {
      this.form.idCardImage[0] = val.url;
    },
    getDeviceType() {
      this.$get(this.$api.deviceType, {}).then((res) => {
        if (res.code == 1) {
          this.deviceTypeList = res.data;
        }
      });
    },
    getDeviceManufacturer() {
      this.$get(this.$api.deviceManufacturer, {}).then((res) => {
        if (res.code == 1) {
          this.deviceManufacturerList = res.data;
        }
      });
    },
    getDeviceTypeNo() {
      if (
        this.$isEmpty(this.form.deviceType) ||
        this.$isEmpty(this.form.deviceManufacturer)
      ) {
        return false;
      }
      const data = {
        deviceType: this.form.deviceType,
        deviceManufacturer: this.form.deviceManufacturer,
      };
      this.$post(this.$api.deviceTypeNo, data).then((res) => {
        if (res.code == 1) {
          this.deviceTypeNoList = res.data;
        }
      });
    },
    setNumber() {
      const total = this.form.posSnEnd - this.form.posSnStart;
      if (this.form.posSnEnd == this.form.posSnStart) {
        this.form.counts = 1;
        return true;
      }
      if (total < 1) {
        this.$message({
          type: "warning",
          message: "输入异常数据",
        });
        this.form.counts = 0;
        return false;
      }
      this.form.counts = total + 1;
      return true;
    },
    setTypeNo() {
      if(this.type !== '' ){
        var item = this.deviceTypeNoList[this.type];
        this.form.typeNo = item.typeNo;
        this.form.prefix = item.prefix;
      }else{
        this.form.typeNo = '';
        this.form.prefix = '';
      }
    },
  },
  created() {
    this.getDeviceType();
    this.getDeviceManufacturer();
    let user_info = localStorage.getItem("user_info");
    let user = JSON.parse(user_info);
    this.form.creater = user.account || user.phone;
  },
};
</script>
<style lang="scss" scoped>
.el-card {
  text-align: right;
  margin-top: 30px;
}

.hight {
  height: 90px;
}

.el-select {
  width: 100%;
}

.el-input {
  width: 100%;
}

.el-divider--horizontal {
  margin: 12px 0px 12px 0px;
}

.price {
  width: 200px;
}

.el-input-number--small {
  width: 200px;
}
</style>
